@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 218 100% 62%;
    --secondary: 219 100% 92%;
    --main: 218 100% 75%;
    --textPrimary: 0 0% 58%;
    --bgColor: 0 0% 100%;
    --text: 0 0% 100%;
    --background: 0 0% 98%;
    --textTitle: 0 0% 0%;
    --borderBottom: 200 4% 87%;
    --redbgColor: 0, 44%, 87%;
    --redTextColor: 0, 100%, 31%;
    --primarybgColor: 162, 45%, 87%;
    --primarytextColor: 154, 51%, 41%;
    --disablebgColor: 214, 18%, 93%;
    --orangebgColor: 22, 100%, 53%, 0.19;
    --orangetextColor: 25, 100%, 44%;
    --deleteButton: 0, 0%, 44%;
    --textsecondary: 0, 1%, 76%;
    --bgTooltip: 0, 0%, 100%, 1;
    --textTooltip: 0, 0%, 43%, 1;
    --borderTootip: 200, 4%, 87%, 1;
    --hoverbgColor: 0 0% 93%;
    --uploadText: 0, 1%, 37%, 0.9;
    --deleteBtnText: 0, 0%, 100%, 0.9;
    --marginGreenBg: 162, 44%, 87%;
    --marginGreenText: 154, 51%, 41%;
    --marginRedBg: 0, 44%, 87%;
    --marginRedText: 0, 100%, 31%;
    --marginOrangeBg: 14, 48%, 88%;
    --marginOrangeText: 27, 97%, 43%;
    --emptyText: 60, 0%, 37%;
    --textColor: 0, 0%, 52%;
    --cardTitle: 0, 0%, 40%;
    --subModalBg: 0, 0%, 93%;
    --scrollBar: 0, 3%, 92%;
    --checkBg: 218, 94%, 94%;
    --checkGra: linear-gradient(180deg, #82b0ff 0%, #488bff 100%);
    --textFooter: 0, 0%, 0%, 0.31;
    --textHeader: 218, 100%, 62%, 1;
  }

  .dark {
    --primary: 162 100% 59%;
    --secondary: 167 57% 9%;
    --main: 162 71% 35%;
    --textPrimary: 0 0% 58%;
    --bgColor: 240 25% 3%;
    --text: 0 0% 0%;
    --background: 240 25% 3%;
    --textTitle: 0 0% 100%;
    --borderBottom: 240 4% 22%;
    --redbgColor: 354, 57%, 11%;
    --redTextColor: 0, 62%, 54%;
    --primarybgColor: 170, 71%, 8%;
    --primarytextColor: 155, 63%, 56%;
    --disablebgColor: 240, 8%, 13%;
    --orangebgColor: 20, 59%, 15%;
    --orangetextColor: 30, 93%, 54%;
    --deleteButton: 240, 7%, 11%;
    --textsecondary: 0, 1%, 76%;
    --bgTooltip: 240, 6%, 14%, 1;
    --textTooltip: 0, 0%, 75%, 1;
    --borderTootip: 240, 4%, 22%, 1;
    --hoverbgColor: 0 0% 10%;
    --uploadText: 0, 0%, 100%, 0.9;
    --deleteBtnText: 0, 0%, 56%, 1;
    --marginGreenBg: 166, 72%, 9%;
    --marginGreenText: 155, 63%, 56%;
    --marginRedBg: 353, 58%, 11%;
    --marginRedText: 0, 62%, 54%;
    --marginOrangeBg: 20, 59%, 15%;
    --marginOrangeText: 30, 93%, 54%;
    --emptyText: 0, 0%, 59%;
    --textColor: 0, 0%, 39%;
    --cardTitle: 0, 0%, 100%;
    --subModalBg: 240, 7%, 11%;
    --scrollBar: 240, 5%, 19%;
    --checkBg: 165, 67%, 11%;
    --checkGra: linear-gradient(180deg, #18ffb9 0%, #0e996f 100%);
    --textFooter: 0, 0%, 100%, 0.31;
    --textHeader: 162, 100%, 59%, 1;
  }
}

body {
  @apply bg-background;
}

.disabled-link {
  pointer-events: none;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: "hsl(var(--background))";
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.scrollHide::-webkit-scrollbar {
  display: none;
}

.scrollHide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chartHeight {
  max-height: calc(100vh - 540px);
}

.custom-checkbox {
  display: flex;
  align-items: center;
}
.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkmark {
  display: inline-block;
  background-color: hsl(var(--checkBg));
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox .filterCheckmark {
  display: inline-block;
  background-color: hsl(var(--checkBg));
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox input:checked + .checkmark {
  background: var(--checkGra);
}
.custom-checkbox input:checked + .filterCheckmark {
  background: var(--checkGra);
}

.custom-checkbox .filterCheckmark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 6px;
  height: 13px;
  border: solid hsl(var(--background));
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  left: 10px;
  top: 3px;
  width: 10px;
  height: 20px;
  border: solid hsl(var(--background));
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.custom-checkbox input:checked + .checkmark::after {
  opacity: 1;
}
.custom-checkbox input:checked + .filterCheckmark::after {
  opacity: 1;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(var(--scrollBar)) !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.svgIcon-close svg {
  height: 30px;
  width: 30px;
}

.border-selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  width: 2px;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(197, 197, 197, 0) 7%,
    #ffffff 50.35%,
    rgba(153, 153, 153, 0) 92%
  ); /* Ensure this matches your intended color */
}
